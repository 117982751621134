import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirstPageComponent } from './admin/components/fist-page/first-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    data: {
      title: 'Dashboard'
    }
  },
  {
    path: 'dashboard',
    redirectTo: '', pathMatch: 'full' 
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: 'admin',
    component: FirstPageComponent,
    data: {
      title: 'Admin'
    }
  },
  {
    path: 'change-password/id/:idPasswordRequest',
    component: ChangePasswordComponent,
    data: {
      title: 'Change password'
    }
  },
  {
    path: 'create-password/id/:idPasswordRequest',
    component: ChangePasswordComponent,
    data: {
      title: 'Create password'
    }
  },
  {
    path: '**',
    redirectTo: '', 
    pathMatch: 'full' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
  })

export class AppRoutingModule { }
